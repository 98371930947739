import {
  MedicalInsuranceResponse,
  PayerPartnerControllerService,
  PharmacyBenefitsControllerService,
  PharmacyInsuranceRequest
} from "@9amhealth/openapi";
import { FormValues } from "atom/autoform/AutoFormBloc";
import { AutoFormControlsResult } from "atom/autoform/useAutoFormControls";
import { Cubit } from "blac-next";
import { AutocompleteItem } from "molecule/autocomplete/Autocomplete";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { AddPharmacyInsuranceDocuments } from "src/state/PatientPharmacyInsuranceBloc/PatientPharmacyInsuranceBloc";
import { z } from "zod";
import BlockingLoadingOverlayController from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { tracker } from "src/state/state";
import { AppQueryPopupsController } from "../AppQueryPopups/AppQueryPopupsBloc";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { subscriberSchema } from "components/MedicalInsuranceForm/MedicalInsuranceSubscriberInformationForm";
import primaryPlanOwner = PharmacyInsuranceRequest.primaryPlanOwner;

export enum AddMedicalInsuranceInfoAction {
  SELECT_HEALTH_PLAN = "select-health-plan",
  ADD_CARD_DETAILS = "add-card-details",
  ADD_CARD_PHOTOS = "add-card-photos",
  SUBSCRIBER_INFO = "subscriber-info"
}

export enum MedicalInsurancePlanNameOption {
  HMO = "HMO",
  PPO = "PPO"
}

export const schemaCardDetails = z.object({
  cardholderId: z.string().min(1).max(20),
  group: z.string().min(1).max(15),
  planOwner: z.nativeEnum(PharmacyInsuranceRequest.primaryPlanOwner),
  planName: z.string().min(1)
});

const healthPlanRequiredError = translate("required_field", {
  label: translate("form.label_medicalInsuranceHealthPlan")
});

export const schemaHealthplan = z.object({
  planId: z
    .string({
      required_error: healthPlanRequiredError,
      invalid_type_error: healthPlanRequiredError
    })
    .min(3, healthPlanRequiredError)
});

export const availableOwnerOptions: AutocompleteItem[] = [
  {
    id: PharmacyInsuranceRequest.primaryPlanOwner.SELF,
    label: translate("form.label_medicalInsurancePlanOwner_self"),
    name: PharmacyInsuranceRequest.primaryPlanOwner.SELF
  },
  {
    id: PharmacyInsuranceRequest.primaryPlanOwner.SPOUSE,
    label: translate("form.label_medicalInsurancePlanOwner_spouse"),
    name: PharmacyInsuranceRequest.primaryPlanOwner.SPOUSE
  },
  {
    id: PharmacyInsuranceRequest.primaryPlanOwner.PARENT,
    label: translate("form.label_medicalInsurancePlanOwner_parent"),
    name: PharmacyInsuranceRequest.primaryPlanOwner.PARENT
  },
  {
    id: PharmacyInsuranceRequest.primaryPlanOwner.OTHER,
    label: translate("form.label_medicalInsurancePlanOwner_other"),
    name: PharmacyInsuranceRequest.primaryPlanOwner.OTHER
  }
];

export interface MedicalInsuranceFormState {
  step: AddMedicalInsuranceInfoAction;
  allImagesSelected: boolean;
  medicalInsurances: MedicalInsuranceResponse[];
  currentPrimaryMedicalInsurance?: MedicalInsuranceResponse;
  initialCardFormValues?: Partial<FormValues<typeof schemaCardDetails>>;
  initialHealthPlanFormValues?: Partial<FormValues<typeof schemaHealthplan>>;
  initialSubscriberInformationFormValues?: Partial<
    FormValues<typeof subscriberSchema>
  >;
  healthPlanOptions?: AutocompleteItem[];
  availablePlanNameOptions: AutocompleteItem[];
}

export default class MedicalInsuranceFormBloc extends Cubit<MedicalInsuranceFormState> {
  cardFormData: FormValues<typeof schemaCardDetails> | null = null;
  selectedHealthPlanId: string | null = null;
  subscriberInformation: FormValues<typeof subscriberSchema> | null = null;
  cardPhotoFileIds: Record<AddPharmacyInsuranceDocuments, string> | null = null;
  effectiveStartDate: string | undefined;
  effectiveEndDate: string | undefined;

  constructor() {
    super({
      medicalInsurances: [],
      step: AddMedicalInsuranceInfoAction.SELECT_HEALTH_PLAN,
      allImagesSelected: false,
      availablePlanNameOptions: [
        {
          id: MedicalInsurancePlanNameOption.HMO,
          label: translate("form.label_medicalInsurancePlanName_hmo"),
          name: MedicalInsurancePlanNameOption.HMO
        },
        {
          id: MedicalInsurancePlanNameOption.PPO,
          label: translate("form.label_medicalInsurancePlanName_ppo"),
          name: MedicalInsurancePlanNameOption.PPO
        }
      ],
      initialCardFormValues: {
        planOwner: PharmacyInsuranceRequest.primaryPlanOwner.SELF,
        planName: MedicalInsurancePlanNameOption.HMO
      }
    });
    void this.init();
  }

  init = async () => {
    void this.loadPrimaryPlan();
  };

  setInitialStep = () => {
    this.patch({
      step: AddMedicalInsuranceInfoAction.SELECT_HEALTH_PLAN
    });
  };

  setAvailablePlanOptions = (insurance: MedicalInsuranceResponse) => {
    const { availablePlanNameOptions } = this.state;

    const optionAlreadyExist = this.state.availablePlanNameOptions.some(
      (option) => option.id === insurance.planName
    );

    if (!optionAlreadyExist && insurance.planName) {
      availablePlanNameOptions.push({
        id: insurance.planName,
        label: insurance.planName,
        name: insurance.planName
      });
    }

    this.patch({
      availablePlanNameOptions
    });
  };

  loadPrimaryPlan = async (): Promise<void> => {
    try {
      const response =
        await PharmacyBenefitsControllerService.fetchPharmacyBenefitsByUser();
      const list = response.data.medicalInsurances ?? [];
      for (const insurance of list) {
        if (insurance.isPrimary) {
          this.patch({
            currentPrimaryMedicalInsurance: insurance,
            initialCardFormValues: {
              cardholderId: insurance.cardHolderId,
              group: insurance.rxGrp,
              planOwner: insurance.primaryPlanOwner,
              planName: insurance.planName as MedicalInsurancePlanNameOption
            },
            initialHealthPlanFormValues: {
              planId: insurance.partnerId
            },
            initialSubscriberInformationFormValues: insurance.guarantor
          });

          this.effectiveStartDate = insurance.effectiveStartDate;
          this.effectiveEndDate = insurance.effectiveEndDate;

          this.cardPhotoFileIds = {
            [AddPharmacyInsuranceDocuments.FRONT]:
              insurance.frontPhotoFileId ?? "",
            [AddPharmacyInsuranceDocuments.BACK]:
              insurance.backPhotoFileId ?? ""
          };

          this.patch({
            allImagesSelected: Boolean(
              insurance.frontPhotoFileId && insurance.backPhotoFileId
            )
          });

          this.autoFormControlsCardDetails?.setValues({
            group: insurance.rxGrp,
            cardholderId: insurance.cardHolderId,
            planOwner: insurance.primaryPlanOwner
          });

          this.autoFormControlsHealthPlan?.setValues({
            planId: insurance.partnerId
          });

          this.setAvailablePlanOptions(insurance);
        }
      }

      this.patch({
        medicalInsurances: list
      });
    } catch (error) {
      reportErrorSentry(new Error("Failed to load primary plan"));
      console.error(error);
    }
  };

  handleFormSubmitHealthplan = (
    values: FormValues<typeof schemaHealthplan>
  ) => {
    this.selectedHealthPlanId = values.planId;

    this.patch({
      step: AddMedicalInsuranceInfoAction.ADD_CARD_DETAILS
    });
  };

  handleFormSubmitSubscriberInfo = (
    values: FormValues<typeof subscriberSchema>
  ) => {
    this.subscriberInformation = values;

    this.patch({
      step: AddMedicalInsuranceInfoAction.ADD_CARD_PHOTOS
    });
  };

  handleFormSubmitCardDetails = (
    values: FormValues<typeof schemaCardDetails>
  ) => {
    this.cardFormData = values;

    if (values.planOwner !== primaryPlanOwner.SELF) {
      if (!this.subscriberInformation) {
        this.patch({
          step: AddMedicalInsuranceInfoAction.SUBSCRIBER_INFO
        });
        return;
      }
    }

    this.patch({
      step: AddMedicalInsuranceInfoAction.ADD_CARD_PHOTOS
    });
  };

  handleFileUploadCardPhotoChange = (
    fileIds: Record<AddPharmacyInsuranceDocuments, string>,
    allSelected: boolean
  ) => {
    if (allSelected) {
      this.cardPhotoFileIds = fileIds;
      this.patch({ allImagesSelected: true });
    }
  };

  handleSaveCardPhotos = () => {
    if (!this.cardPhotoFileIds) {
      reportErrorSentry(new Error("Card photo file ids are missing"));
      this.patch({
        allImagesSelected: false,
        step: AddMedicalInsuranceInfoAction.ADD_CARD_PHOTOS
      });
      return;
    }

    void this.saveInsurance();
  };

  saveInsurance = async () => {
    const { selectedHealthPlanId } = this;

    if (!selectedHealthPlanId) {
      this.patch({
        step: AddMedicalInsuranceInfoAction.SELECT_HEALTH_PLAN
      });
      return;
    }

    const { cardholderId, group, planOwner, planName } =
      this.cardFormData ?? {};

    if (!cardholderId || !group || !planOwner || !planName) {
      this.patch({
        step: AddMedicalInsuranceInfoAction.ADD_CARD_DETAILS
      });
      return;
    }

    if (planOwner !== primaryPlanOwner.SELF) {
      if (!this.subscriberInformation) {
        this.patch({
          step: AddMedicalInsuranceInfoAction.SUBSCRIBER_INFO
        });
        return;
      }
    }

    const frontPhotoFileId =
      this.cardPhotoFileIds?.[AddPharmacyInsuranceDocuments.FRONT];
    const backPhotoFileId =
      this.cardPhotoFileIds?.[AddPharmacyInsuranceDocuments.BACK];

    if (!frontPhotoFileId || !backPhotoFileId) {
      this.patch({
        allImagesSelected: false,
        step: AddMedicalInsuranceInfoAction.ADD_CARD_PHOTOS
      });
      return;
    }

    try {
      BlockingLoadingOverlayController.startLoading({
        bg: "transparent"
      });

      await PharmacyBenefitsControllerService.createOrUpdateBenefitsByUser({
        medicalInsurances: [
          {
            guarantor: this.subscriberInformation ?? undefined,
            cardHolderId: cardholderId,
            rxGrp: group,
            status: PharmacyInsuranceRequest.status.ACTIVE,
            frontPhotoFileId,
            backPhotoFileId,
            planName,
            isPrimary: true,
            primaryPlanOwner: planOwner,
            partnerId: selectedHealthPlanId,
            effectiveStartDate: this.effectiveStartDate,
            effectiveEndDate: this.effectiveEndDate
          }
        ]
      });
      this.handleSaved();
      BlockingLoadingOverlayController.loadingSuccess();
    } catch (e) {
      reportErrorSentry(new Error("Failed to save insurance", { cause: e }));
      BlockingLoadingOverlayController.loadingError({
        title: translate("error_saving_medical_insurance")
      });
    }
  };

  handleSaved = () => {
    tracker.track("Medical Insurance Card Added/Updated" as TrackEvent);
    AppQueryPopupsController.closePopup();
  };

  autoFormControlsCardDetails?: AutoFormControlsResult<
    typeof schemaCardDetails
  >;
  defineCardDetailsFormControls = (
    autoFormControls: AutoFormControlsResult<typeof schemaCardDetails>
  ) => {
    this.autoFormControlsCardDetails = autoFormControls;
  };

  autoFormControlsHealthPlan?: AutoFormControlsResult<typeof schemaHealthplan>;
  defineHealthPlanFormControls = (
    autoFormControls: AutoFormControlsResult<typeof schemaHealthplan>
  ) => {
    this.autoFormControlsHealthPlan = autoFormControls;
  };

  fetchHealthPlanOptions = async () => {
    try {
      const response = await PayerPartnerControllerService.providePayerList();
      const list = response.data;
      const options: AutocompleteItem[] = list.map((item) => ({
        id: item.payerId,
        label: item.displayName,
        name: item.payerId
      }));
      this.patch({ healthPlanOptions: options });
    } catch (e) {
      console.error(e);
    }
  };
}
